import Splide from '@splidejs/splide';

document.addEventListener( 'DOMContentLoaded', function () {

  //check if element exists on page
  var smallGallery = document.getElementById("thumbnail-carousel");
  var mainGallery = document.getElementById("main-carousel");
  if(smallGallery == null || mainGallery == null){
    return
  }

  var thumbnails = new Splide( '#thumbnail-carousel', {
    fixedWidth : 100,
    fixedHeight: 60,
    gap        : 10,
    rewind     : true,
    pagination : false,
    focus      : 'center',
    isNavigation: 'true',
    breakpoints: {
      600: {
        fixedWidth : 60,
        fixedHeight: 44,  
      },
    },
  } );

  var main = new Splide( '#main-carousel', {
    focus      : 'center',
    fixedHeight: '55vh',
    type      : 'fade',
    rewind    : true,
    pagination: false,
    arrows    : false,
  } );

  thumbnails.mount();
  main.sync(thumbnails);
  main.mount();

});