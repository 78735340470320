window.onscroll = function() {
    scrollFunction()
};

const header = document.getElementsByClassName('content-page-header');

function scrollFunction() {
    if(header.length != 0){
        if (document.documentElement.scrollTop > 80) {
            header[0].classList.add('fixedBar');
        } else if(document.documentElement.scrollTop < 10){
            header[0].classList.remove('fixedBar');
        }
    }
}
